import React from "react";
import "./Coin.css"


const Coin = ({ name, image, price, pricechange, marketcap }) => {
  return (
    <div className="coin-container">
      <div className="coin-row">
        <div className="coin">
        <h1>{name}</h1>

          <img src={image} alt="crypto" />
          <p className="coin-symbol"></p>
        </div>
        <div className="coin-data">
          <p className="coin-price"> Rs: {price}</p>
          {pricechange < 0 ? (
            <p className="coin-percent red">{pricechange.toFixed(2)}%</p>
          ) : (
            <p className="coin-percent green">{pricechange.toFixed(2)}%</p>
          )}
          <p className="coin-marketcap">
            Mkt Cap: Rs. {marketcap.toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Coin;
