import React, { useEffect, useState } from 'react';
import Coin from "./Coin"
import "./App.css"

const  App = ()=> {
  const [coins, setCoins] = useState([]);
  const [search, setSearch] = useState("");


useEffect( ()=>{
  const  fetchApi = async()=>{
    const url = "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"
    const response = await fetch(url);
    const resJson = await response.json();
    // console.log(resJson.data)
    setCoins(resJson)
  };
  fetchApi();
}, [])
const handleChange = (e)=>{
  setSearch(e.target.value)
}
const filteredCoins = coins.filter(coin=>
  coin.name.toLowerCase().includes(search.toLowerCase())
  )
  return (
    <>
    <h1>
      Crypto App
    </h1>
     <div className='coin-search'>
      <form action="">
       <input type="text" className='coin-text' placeholder='Enter Coin name' onChange={handleChange}/>
    </form>
     </div>


     
    {!coins ? (
      <p>No coin found</p>
    ) : (

      <div className='coin-app'>

      {filteredCoins.map((coin)=>{
        return(
          <Coin
          key={coin.id}
          name={coin.name}
          image={coin.image}
          symbol={coin.symbol}
          marketcap={coin.market_cap}
          price={coin.current_price}
          pricechange={coin.price_change_percentage_24h}
          />
        )
      })}
    </div>
    )}




    </>
  )
}

export default App
